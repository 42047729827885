<!--混凝土生产完整记录-->
<template>
    <div class="productionRecord">
        <div class="records-line">
            <div
                :class="{'record-item':true,'has-record':deliverInfo.created,'active':!deliverInfo.receive_time} "
            >
                <div class="line">
                    <i class="iconfont icon19"></i>
                </div>
                <div class="pot-info">
                    <strong>派单</strong>
                    <label v-if="deliverInfo.created">{{ (deliverInfo.created||'').substr(0,10) }}</label>
                    <span>{{ (deliverInfo.created||'').substr(11) }}</span>
                </div>
            </div>
            <div
                :class="{'record-item':true,'has-record':deliverInfo.receive_time,'active':!deliverInfo.product_time} "
            >
                <div class="line">
                    <i class="iconfont icon19"></i>
                </div>
                <div class="pot-info">
                    <strong>接单</strong>
                    <label v-if="!deliverInfo.product_time">{{ (deliverInfo.receive_time||'').substr(0,10) }}</label>
                    <span>{{ (deliverInfo.receive_time||'').substr(11) }}</span>
                </div>
            </div>
            <div
                :class="{'record-item':true,'has-record':deliverInfo.product_time,'active':!deliverInfo.product_complete_time} "
            >
                <div class="line">
                    <i class="iconfont icon19"></i>
                </div>
                <div class="pot-info">
                    <strong>首盘打料</strong>
                    <label v-if="!deliverInfo.product_complete_time">{{ (deliverInfo.product_time||'').substr(0,10) }}</label>
                    <span>{{ (deliverInfo.product_time||'').substr(11) }}</span>
                </div>
            </div>
            <div
                :class="{'record-item':true,'has-record':deliverInfo.product_complete_time,'active':!deliverInfo.out_time} "
            >
                <div class="line">
                    <i class="iconfont icon19"></i>
                </div>
                <div class="pot-info">
                    <strong>打料完成</strong>
                    <label v-if="!deliverInfo.out_time">{{ (deliverInfo.product_complete_time||'').substr(0,10) }}</label>
                    <span>{{ (deliverInfo.product_complete_time||'').substr(11) }}</span>
                </div>
            </div>
            <div
                :class="{'record-item':true,'has-record':deliverInfo.out_time,'active':!deliverInfo.arrival_time} "
            >
                <div class="line">
                    <i class="iconfont icon19"></i>
                </div>
                <div class="pot-info">
                    <strong>出场</strong>
                    <label v-if="!deliverInfo.arrival_time">{{ (deliverInfo.out_time||'').substr(0,10) }}</label>
                    <span>{{ (deliverInfo.out_time||'').substr(11) }}</span>
                </div>
            </div>
            <div
                :class="{'record-item':true,'has-record':deliverInfo.arrival_time,'active':!deliverInfo.unload_over_time} "
            >
                <div class="line">
                    <i class="iconfont icon19"></i>
                </div>
                <div class="pot-info">
                    <strong>到达工地</strong>
                    <label v-if="!deliverInfo.unload_time">{{ (deliverInfo.arrival_time||'').substr(0,10) }}</label>
                    <span>{{ (deliverInfo.arrival_time||'').substr(11) }}</span>
                </div>
            </div>
            <div
                :class="{'record-item':true,'has-record':deliverInfo.unload_time,'active':!deliverInfo.unload_over_time} "
            >
                <div class="line">
                    <i class="iconfont icon19"></i>
                </div>
                <div class="pot-info">
                    <strong>开始卸料</strong>
                    <label v-if="!deliverInfo.unload_over_time">{{ (deliverInfo.unload_time||'').substr(0,10) }}</label>
                    <span>{{ (deliverInfo.unload_time||'').substr(11) }}</span>
                </div>
            </div>
            <div
                :class="{'record-item':true,'has-record':deliverInfo.unload_over_time} "
            >
                <div class="line">
                    <i class="iconfont icon19"></i>
                </div>
                <div class="pot-info">
                    <strong>卸料完成</strong>
                    <label v-if="deliverInfo.unload_over_time">{{ (deliverInfo.unload_over_time||'').substr(0,10) }}</label>
                    <span>{{ (deliverInfo.unload_over_time||'').substr(11) }}</span>
                </div>
            </div>
            <div class="record-item last-record">
                <div class="line">
                    <i class="iconfont iconicon-up"></i>
                </div>
            </div>
        </div>

        <div class="productionReady">
            <h1 class="tag-notice">
                <i class="iconfont iconshijian2"></i>工地下计划，准备生产
            </h1>
            <div class="content-wrapper">
                <div class="slide-menus">
                    <div
                        v-for="(item,index) in menus"
                        :key="item.name"
                        @click="handleActiveMenu(item)"
                        :class="{'menus-item':true,'active':activeMenu==item}"
                    >
                        <i>{{ index+1 }}</i><span>{{ item.name }}</span>
                    </div>
                </div>
                <div class="list-wrapper ">
                    <iframe
                        v-if="activeMenu.type==='iframe'"
                        frameborder="0"
                        class="formIframe"
                        id="frameIdPO202001140931270228"
                        :src="activeMenu.url"
                    ></iframe>
                    <component v-else :is="activeMenu.url" :extr="params"></component>
                </div>
            </div>
            <h1 class="tag-notice">
                <i class="iconfont iconshijian2"></i>直至发货单核算、对账、结算
            </h1>
        </div>
        <div class="btns-wrapper">
            <div class="close-btn" @click="handleClose">
                关闭
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'production-record',
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            // 数据信息
            deliverInfo: {},
            menus: [
                {
                    name: '订单信息',
                    type: 'iframe',
                    url: '/angularForm/FormView.html?FormCode=PO202001140931270228&IsView=true&frameId=frameIdPO202001140931270228&PKValues=',
                },
                {
                    name: '派单信息',
                    type: 'component',
                    url: () => import('@/views/productionManagement/productionDelivery/deliveryDetail.vue'),
                },
                {
                    name: '生产信息',
                    type: 'component',
                    url: () => import('@/views/productionManagement/productionDeliverGoodsJournal/logList.vue'),
                },
                {
                    name: '运输信息',
                    type: 'component',
                    url: () => import('./transportLine.vue') },
                {
                    name: '交付信息',
                    type: 'component',
                    url: () => import('@/views/logisticsAdministration/deliveryEvaluationInfo.vue') },
                {
                    name: '账务信息',
                    type: 'iframe',
                    url: '/angularForm/FormView.html?FormCode=xs_accounts_ysd_hs&IsView=true&frameId=frameIdxs_accounts_ysd_hs&PKValues=',
                },
                {
                    name: '管控数据',
                    type: 'component',
                    url: () => import('./controlInstanceList.vue'),
                },
                { name: '原材料消耗', type: 'component', url: () => import('./materialConsumption.vue') },
            ],

            // 选中的菜单
            activeMenu: {},
            // 数据列表
            dataList: [],

            // 参数
            params: {
                task_number: null,
                pcbNumber: null,
                pcb_number: null,
                plate_number: null,
                out_time: null,
                arrival_time: null,
            },
        };
    },
    created() {
        this.initPage();
    },
    methods: {
        initPage() {
            this.deliverInfo = this.extr.row;
            this.params.task_number = this.extr.task_number;
            this.params.pcbNumber = this.params.pcb_number = this.deliverInfo.pcb_number;
            this.menus.forEach(item => {
                if (item.name === '订单信息') {
                    item.url += this.deliverInfo.poid;
                } else if (item.name === '账务信息') {
                    item.url += this.deliverInfo.id;
                }
            });
            // this.params.poid = this.deliverInfo.poid;
            this.params.plate_number = this.deliverInfo.plate_number;
            this.params.out_time = this.deliverInfo.out_time || this.deliverInfo.product_complete_time || '';
            this.params.arrival_time = this.deliverInfo.arrival_time || this.deliverInfo.unload_time || '';
            this.activeMenu = this.menus[0];
        },
        handleActiveMenu(item) {
            this.activeMenu = item;
        },

        handleClose() {
            this.$parent.hide();
        },
    },
};
</script>

<style lang="stylus" scoped>
.productionRecord
    width 100%;
    height 100%;
    .records-line
        width 100%;
        height 0.8rem
        border: 1px solid #D7D7D7;
        display flex
        padding-left 0.4rem
        background #F8F8F8
        .record-item
            width 1.6rem
            height 100%;
            display flex
            align-items center
            position relative
            .line
                width 100%
                height 0.04rem
                background #979EA7
                position relative
                display flex
                justify-content flex-end
                align-items center
                margin-right 0.08rem
                .iconfont
                    margin-right: -0.14rem;
                    font-size 0.16rem;
                    color #979EA7;
                    z-index 2
            .pot-info
                width 0.76rem
                height 100%;
                padding 0.04rem 0
                position absolute
                right -0.36rem
                top 0
                display flex
                flex-direction column
                justify-content space-between
                align-items center
                z-index 9
                strong
                    display flex
                    justify-content center
                    align-items center
                    height 0.2rem;
                    white-space nowrap
                    font-size 0.14rem;
                    color #979EA7
                label
                    padding 1px 0.03rem;
                    font-size 0.12rem
                    color #0091FF
                    background: #fff;
                    border: 1px solid #C3D6FF;
                    border-radius: 0.02rem;
                    position relative
                    &:before
                        box-sizing: content-box;
                        width: 0px;
                        height: 0px;
                        position: absolute;
                        top: 0.2rem;
                        left: 0.32rem;
                        padding: 0;
                        border-right: 4px solid transparent;
                        border-top: 4px solid #fff;
                        border-bottom: 4px solid transparent;
                        border-left: 4px solid transparent;
                        display: block;
                        content: '';
                        z-index: 12;
                    &:after
                        box-sizing: content-box;
                        width: 0px;
                        height: 0px;
                        position: absolute;
                        top: 0.21rem;
                        left: 0.32rem;
                        padding: 0;
                        border-right: 4px solid transparent;
                        border-top: 4px solid #c3d6ff;
                        border-bottom: 4px solid transparent;
                        border-left: 4px solid transparent;
                        display: block;
                        content: '';
                        z-index: 10;
                span
                    color #666666
                    font-size 0.14rem;
            &.has-record
                .line
                    background #0091FF
                    .iconfont
                        color #0091FF;
                .pot-info
                    strong
                        color #0091FF
            &.last-record
                .line .iconfont
                    font-size 0.24rem
                    margin-right: -0.1rem;
            &.active
                .line .iconfont,.pot-info strong
                    font-size 0.18rem
                    color #FA6400
    .productionReady
        padding 0.2rem;
        height calc(100% - 1.6rem)
        .tag-notice
            color #333
            font-size 0.14rem
            .iconfont
                margin-right 0.05rem
        .content-wrapper
            margin-top 0.1rem;
            width 100%;
            height calc(100% - 0.5rem)
            display flex
            .slide-menus
                width 1.7rem
                height 100%;
                overflow-y auto
                .menus-item
                    display flex
                    align-items center
                    margin 0.15rem 0;
                    width 1.6rem;
                    height 0.44rem
                    background: #E6F7FF;
                    border-radius: 2px;
                    border: 1px solid #2898FF;
                    border-left-width 0.04rem
                    color #1890FF
                    cursor pointer
                    &:nth-child(1)
                        margin-top 0
                    i
                        margin-left 0.1rem
                        margin-right 0.1rem
                        display inline-block
                        width 0.28rem
                        height 0.28rem
                        line-height 0.28rem;
                        text-align center
                        background url("../../images/icon_normal.png") no-repeat
                        background-size 100% 100%
                    &.active
                        color #fff;
                        background: #0091FF;
                        box-shadow: 0 0.02rem 0.06rem 0 rgba(0, 39, 69, 0.5);
                        i
                            color #0091FF
                            background url("../../images/icon_highlight.png") no-repeat
                            background-size 100% 100%
            .list-wrapper
                padding-left 0.1rem
                left 0.1rem
                width calc(100% - 1.7rem)
                height 100%
                overflow-y auto
                iframe
                    width 100%;
                    height 98%;
                >>> footer,>>> .btn-wrapper
                    display none;
    .btns-wrapper
        display flex
        justify-content center
        .close-btn
            width: 2.6rem;
            height: 0.5rem;
            border: 0.02rem solid #1577D2;
            display flex
            justify-content center
            align-items center
            font-size 0.2rem
            color #1577D2
            cursor pointer
</style>
